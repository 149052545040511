function menuToggle(x) {
    x.classList.toggle("change");
}


$readMore = $('.box-read-more'); // This is the content (wrapper) for each read more text.

function read_more_mobile() {

    if ($(window).width() < 768) {
        $readMore.addClass('box-read-more-mobile');
    } else {
        $readMore.removeClass('box-read-more-mobile').readmore('destroy');
    }
}

function addMobileClass() {
    if ($(window).width() < 768) {
        $readMore.addClass('box-read-more-mobile');
    }
    else {
        $readMore.removeClass('box-read-more-mobile');
    }
}

function ReadMoreButton(readMoreWrapperClass) {
    $('.' + readMoreWrapperClass).readmore({
        speed: 500,
        collapsedHeight: 180,
        lessLink: false,
        moreLink: '\<p class="box-read-more-mobile__link"><a href="#"\>Read more\<\/a\></p>'
    });
}

function mySlides() {

    var slideIndex = 1;

    showSlides(slideIndex);

    function showSlides(n) {
        var i;
        var slides = document.getElementsByClassName("mySlides");
        var dots = document.getElementsByClassName("dot");
        if (n > slides.length) {
            slideIndex = 1
        }
        if (n < 1) {
            slideIndex = slides.length
        }
        for (i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";
        }
        for (i = 0; i < dots.length; i++) {
            dots[i].className = dots[i].className.replace(" active", "");
        }
        slides[slideIndex - 1].style.display = "block";
        dots[slideIndex - 1].className += " active";
    }

    this.plusSlides  = function(n) {
        showSlides(slideIndex += n);
        $('.lazy').unveil();
    };

    this.currentSlide  = function(n) {
        showSlides(slideIndex = n);
        $('.lazy').unveil();
    };
}

$(document).ready(function () {

    /* JACKPOT TICKER */
    $('.jackpot_ticker').filter(function () {
        return $(this).length > 0;
    }).each(function () {
        $(this).addClass('ticking').simpleTick({
            decimal: ".",		// decimal point separator
            thousand: ",",		// thousands separator
            speed: 180, // Speed value: if Max/Min speed are set, this value is unused.
            increment: .39, // Increment value: if Max/Min increment amounts are set, this value is unused.
            currency: 'nzd',
            minSpeed: 90,  // speed is randomly generated between this Max and Min speed values
            maxSpeed: 950,// ^^^^^
            minIncrement: 0.07, // Increment amount is randomly generated between this Max and Min increment values
            maxIncrement: 2.91   //  ^^^^^^
        });
    });

    // Lazy load images, show 100px before they are on the screen
    $('.lazy').unveil(100);

    // Read more for comm pages
    addMobileClass();
    ReadMoreButton('box-read-more-mobile');

    $(".switch__link").on("click", function () {
        $(".switch__link.active").removeClass("active");
        $(this).addClass('active');
    });
    $(".cat__item").on("click", function () {
        $(".cat__item.active").removeClass("active");
        $(this).addClass("active");
    });
    $(".c-pag__item_link").on("click", function () {
        $(".c-pag__item_link.active").removeClass("active");
        $(this).addClass("active");
    });
    $(".header__menu_list_item").on("click", function () {
        $(".header__menu_list_item.active").removeClass("active");
        $(this).addClass("active");
    });

    var $topCasinoLink = $("a[href='#to-top-casinos']");
    if ($topCasinoLink.length) {
        $topCasinoLink.click(function () {
            $("html, body").animate({scrollTop: ($(".top-columns__col-top").offset().top - 35)}, "slow");
            return false;
        });
    }

    $('.faq__qa_text').click(function () {
        var question = $(this).parent();
        // var qa = question.find('.faq__qa_text');
        var state = question.attr('data-state');
        var answer = question.find('.faq__qa_answer');
        var arrow = question.find('.faq__qa_text_ctrl').find('i');

        if (state == 0) {
            answer.slideDown(300);
            question.removeClass('');
            // qa.removeClass('faq__qa_text--color-0');
            // qa.addClass('faq__qa_text--color-1');
            arrow.removeClass('fa-angle-right');
            arrow.addClass('fa-angle-down');
            state = 1;
        } else if (state == 1) {
            answer.slideUp(300);
            arrow.removeClass('fa-angle-down');
            arrow.addClass('fa-angle-right');
            // qa.removeClass('faq__qa_text--color-1');
            // qa.addClass('faq__qa_text--color-0');
            state = 0;
        }

        question.attr('data-state', state);
    });

    $(".menu-icon").click(function () {
        $(this).toggleClass("change");
    });

    if ($('.mySlides').length > 0) {
        mySlides();
    }


//BACK TO TOP
// browser window scroll (in pixels) after which the "back to top" link is shown
    var offset = 300,
        //browser window scroll (in pixels) after which the "back to top" link opacity is reduced
        offset_opacity = 1200,
        //duration of the top scrolling animation (in ms)
        scroll_top_duration = 700,
        //grab the "back to top" link
        $back_to_top = $('.cd-top');

//hide or show the "back to top" link
    $(window).scroll(function () {
        ( $(this).scrollTop() > offset ) ? $back_to_top.addClass('cd-is-visible') : $back_to_top.removeClass('cd-is-visible cd-fade-out');
        if ($(this).scrollTop() > offset_opacity) {
            $back_to_top.addClass('cd-fade-out');
        }
    });

//smooth scroll to top
    $back_to_top.on('click', function (event) {
        event.preventDefault();
        $('body,html').animate({
                scrollTop: 0
            }, scroll_top_duration
        );
    });

//progressbar animation on reviews
    if ($('#contner').length > 0) {

        var settingsLineDesc = {
            strokeWidth: 8,
            easing: 'easeInOut',
            duration: 1400,
            color: '#0dbd31',
            trailColor: '#cdcdcd',
            trailWidth: 8,
            svgStyle: {width: '100%', height: '100%'},
            text: {
                style: {
                    color: '#453636',
                    position: 'absolute',
                    right: '6px',
                    top: '-17px',
                    padding: 0,
                    margin: 0,
                    transform: null
                },
                autoStyleContainer: false
            },
            from: {color: '#FFEA82'},
            to: {color: '#ED6A5A'},
            step: function (state, bar) {
                bar.setText((bar.value() * 10).toFixed(1) + '<span> / 10</span>');
            }
        };

        var bar = new ProgressBar.Line(contner, settingsLineDesc),
            bar1 = new ProgressBar.Line(contner1, settingsLineDesc),
            bar2 = new ProgressBar.Line(contner2, settingsLineDesc),
            bar3 = new ProgressBar.Line(contner3, settingsLineDesc),
            bar4 = new ProgressBar.Line(contner4, settingsLineDesc),
            bar5 = new ProgressBar.Line(contner5, settingsLineDesc),
            bar6 = new ProgressBar.Line(contner6, settingsLineDesc),
            bar7 = new ProgressBar.Line(contner7, settingsLineDesc),
            bar8 = new ProgressBar.Line(contner8, settingsLineDesc);

        bar.animate($(contner).data('rating') / 10); // Number from 0.0 to 1.0
        bar1.animate($(contner1).data('rating') / 10);
        bar2.animate($(contner2).data('rating') / 10);
        bar3.animate($(contner3).data('rating') / 10);
        bar4.animate($(contner4).data('rating') / 10);
        bar5.animate($(contner5).data('rating') / 10);
        bar6.animate($(contner6).data('rating') / 10);
        bar7.animate($(contner7).data('rating') / 10);
        bar8.animate($(contner8).data('rating') / 10);

        var settingsWideLine = {
                strokeWidth: 3,
                easing: 'easeInOut',
                duration: 1400,
                color: '#0dbd31',
                trailColor: '#cdcdcd',
                trailWidth: 3,
                svgStyle: {width: '100%', height: '100%'},
                text: {
                    style: {
                        color: '#fff',
                        position: 'absolute',
                        right: '0px',
                        top: '-36px',
                        width: '97px',
                        padding: '8px 0 0 0',
                        margin: 0,
                        transform: null
                    },
                    autoStyleContainer: false
                },
                from: {color: '#FFEA82'},
                to: {color: '#ED6A5A'},
                step: function (state, bar) {
                    var res = (bar.value() * 10).toFixed(1);
                    if (res == 10) res = 10;
                    bar.setText(res + '<span> / 10</span>');
                }
            },
            revBonus = new ProgressBar.Line(barbonus, settingsWideLine),
            revSoft = new ProgressBar.Line(barsoft, settingsWideLine),
            revBank = new ProgressBar.Line(barbank, settingsWideLine),
            revMGames = new ProgressBar.Line(barmobgame, settingsWideLine),
            revCGames = new ProgressBar.Line(barcasgame, settingsWideLine),
            revSup = new ProgressBar.Line(barsup, settingsWideLine);

        revBonus.animate($(barbonus).data('bonus') / 10);
        revSoft.animate($(barsoft).data('rate') / 10);
        revBank.animate($(barbank).data('rate') / 10);
        revMGames.animate($(barmobgame).data('rate') / 10);
        revCGames.animate($(barcasgame).data('rate') / 10);
        revSup.animate($(barsup).data('rate') / 10);
    }
});


$(window).resize(function () {

    addMobileClass();
    read_more_mobile();
    if ($readMore.hasClass('box-read-more-mobile')) {
        ReadMoreButton('box-read-more-mobile');
    }

});
