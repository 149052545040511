$(document).ready(function() {
  var $window = $(window);

  function topRow() {
    var windowsize = $window.width();

    if (windowsize < 768) {
     $("#ratebox-1").insertAfter($("#bonusbox-1"));
     $("#bonus-1").insertAfter($("#bonusbox-1"));
     $("#ratebox-2").insertAfter($("#bonusbox-2"));
     $("#bonus-2").insertAfter($("#bonusbox-2"));
     $("#ratebox-3").insertAfter($("#bonusbox-3"));
     $("#bonus-3").insertAfter($("#bonusbox-3"));
     $("#ratebox-4").insertAfter($("#bonusbox-4"));
     $("#bonus-4").insertAfter($("#bonusbox-4"));
   } else if (windowsize > 767){
    $("#bonusbox-1").append($("#bonus-1"));
    $("#bonusbox-1").append($("#ratebox-1"));
    $("#bonusbox-2").append($("#bonus-2"));
    $("#bonusbox-2").append($("#ratebox-2"));
    $("#bonusbox-3").append($("#bonus-3"));
    $("#bonusbox-3").append($("#ratebox-3"));
    $("#bonusbox-4").append($("#bonus-4"));
    $("#bonusbox-4").append($("#ratebox-4"));
  } else {};
}
topRow();
$(window).resize(topRow);
});