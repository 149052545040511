$(document).ready(function() {
    var $window = $(window);

    function checkWidth() {
        var windowsize = $(window).width();
        if (windowsize < 768) {
            $("#top-row-1").append($("#row-top-number"));
            $("#top-row-1").append($("#row-top-logo"));
            $("#top-row-1").append($("#row-top-bonus"));
            $("#top-row-1").append($("#row-top-links"));
            $("#top-row-2").append($("#row-top-payout"));
            $("#top-row-2").append($("#row-top-pay-speed"));
            $("#top-row-2").append($("#row-top-games"));
            $("#top-row-3").append($("#row-top-pay-option"));
            $("#top-row-3").append($("#row-top-platform"));
        } else {
            $("#row-top-link").append($("#row-top-number"));
            $("#row-top-link").append($("#row-top-logo"));
            $("#row-top-link").append($("#row-top-bonus"));
            $("#row-top-link").append($("#row-top-payout"));
            $("#row-top-link").append($("#row-top-pay-speed"));
            $("#row-top-link").append($("#row-top-games"));
            $("#row-top-link").append($("#row-top-platform"));
            $("#row-top-link").append($("#row-top-pay-option"));
            $("#row-top-link").append($("#row-top-links"));
        };
        if(windowsize < 481) {
            $( "#row-top-links" ).insertAfter( $( "#top-row-1" ) );
        } else {};


        if (windowsize < 992) {
            $("#main-row-t-1").append($("#main-row-logo"));
            $("#main-row-t-1").append($("#main-row-title"));
            $("#main-row-t-1").append($("#main-row-rate"));
            $("#main-row-t-1").append($("#main-row-sprtr-1"));
            $("#main-row-t-2").append($("#main-row-platform"));
            $("#main-row-t-2").append($("#main-row-bonus"));
            $("#main-row-t-2").append($("#main-row-link"));
            $("#main-row-t-2").append($("#main-row-sprtr-2"));
            $( "#main-row-wrap-link" ).insertBefore( $( "#casino-row-fluid-3" ) );
            $("#main-row-wrap-link").append($("#casino-row-fluid-1"));
            $("#main-row-wrap-link").append($("#casino-row-fluid-2"));
            
        } else {
            $( "#casino-row-fluid-1" ).insertBefore( $( "#main-row-wrap-link" ) );
            $( "#casino-row-fluid-2" ).insertBefore( $( "#main-row-wrap-link" ) );
            $("#main-row-div-1").append($("#main-row-wrap-link"));
            $("#main-row-wrap-link").append($("#main-row-logo"));
            $("#main-row-wrap-link").append($("#main-row-title"));
            $("#main-row-wrap-link").append($("#main-row-platform"));
            $("#main-row-wrap-link").append($("#main-row-bonus"));
            $("#main-row-wrap-link").append($("#main-row-link"));
            $("#main-row-wrap-link").append($("#main-row-sprtr-1"));
            $("#main-row-wrap-link").append($("#main-row-sprtr-2"));
        };

        if (windowsize < 1200) {
            $( "#review-info" ).insertAfter( $( "#review-main__rate" ) );
        } else {
            $( "#review-info" ).insertAfter( $( "#review-card" ) );
        };

        if (windowsize < 992) {
            $( "#oprtr-pay" ).insertAfter( $("#oprtr-supp"));
        } else {
            $( "#oprtr-supp" ).insertAfter( $( "#oprtr-pay" ) );
        };

    }
    checkWidth();
    $(window).resize(checkWidth);
});